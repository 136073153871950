import React from 'react'
import Grid from '@mui/material/Grid'
import PageHeroBg from '../assets/page-hero-bg.webp'
import Portrait from '../assets/portrait-photo.png'
import Singularity from '../assets/singularity.png'
import Team from '../components/About/Team'
import ContactUs from '../components/Home/ContactUs'
import Icons from '../components/Home/Icons'
import '../styles/About/About.css'
import { Container, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Section from '../components/About/Section'

const AboutSections = [
  {
    name: 'about_ceo',
    headline: 'about_ceo_name',
    text: 'about_ceo_text',
    image: Portrait,
    position: 'left',
  },
  {
    name: 'about_singularity',
    headline: 'about_singularity_name',
    text: 'about_singularity_text',
    image: Singularity,
    position: 'right',
  },
]

const About: React.FC = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Container>
        <Grid container spacing={12}>
          <Grid item xs={12}>
            <img src={PageHeroBg} alt="Logo" className={'about_image'} />
          </Grid>
          <Grid item className={'about_grid'} xs={12}>
            <div className={'about_subheading'}>
              {t('about_screen_subheading')}
            </div>
            <h1 className={'about_heading'}>{t('about_screen_heading')}</h1>
            <div className={'about_text'}>{t('about_screen_text')}</div>
          </Grid>
          {AboutSections.map((section) => (
            <Grid key={section.name} item xs={12}>
              <Section {...section} />
            </Grid>
          ))}
          <Grid item xs={12} sx={{ paddingBottom: '4rem' }}>
            <ContactUs />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default About
