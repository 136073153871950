import React from 'react'
import Grid from '@mui/material/Grid'
import '../../styles/Services/Pricing.css'
import { useTranslation } from 'react-i18next'
import { useMediaQuery, useTheme } from '@mui/material'

const Pricing: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const grayText = t('services_pricing_text').split('\n\n')[0]
  const whiteText = t('services_pricing_text').split('\n\n')[2]
  const additionalGrayText = t('services_pricing_text').split('\n\n')[1]

  return (
    <>
      <Grid container className={'pricing_container'}>
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <h2
            style={{
              marginTop: '0px',
              marginBottom: '0px',
              padding: '1rem 0 1rem 0',
              fontSize: '48px',
              fontWeight: '400',
              fontStyle: 'normal',
              lineHeight: '60px',
              textAlign: 'center',
              color: '#FFF',
              whiteSpace: isMobile ? 'unset' : 'pre-line',
            }}
          >
            {t('services_pricing_heading')}
          </h2>
          <div
            style={{
              backgroundColor: '#0076FF',
              height: '2px',
              width: '64px',
            }}
          ></div>
          <span
            style={{
              marginTop: '1rem',
              fontSize: '16px',
              fontWeight: '300',
              fontStyle: 'normal',
              lineHeight: '24px',
              textAlign: 'center',
              color: '#AAA',
              whiteSpace: isMobile ? 'unset' : 'pre-line',
            }}
          >
            {grayText}
          </span>
          <span
            style={{
              marginTop: '1rem',
              fontSize: '16px',
              fontWeight: '300',
              fontStyle: 'normal',
              lineHeight: '24px',
              textAlign: 'center',
              color: '#AAA',
              whiteSpace: isMobile ? 'unset' : 'pre-line',
            }}
          >
            {additionalGrayText}
          </span>
          <span
            style={{
              marginTop: '1rem',
              fontSize: '20px',
              fontWeight: '300',
              fontStyle: 'normal',
              lineHeight: '24px',
              textAlign: 'center',
              color: '#FFF',
              whiteSpace: isMobile ? 'unset' : 'pre-line',
            }}
          >
            {whiteText}
          </span>
        </Grid>
      </Grid>
    </>
  )
}

export default Pricing
