import React from 'react'
import {
  AppBar,
  Button,
  Toolbar,
  useMediaQuery,
  useTheme,
  useScrollTrigger,
} from '@mui/material'
import DrawerComp from './Drawer'
import Logo from '../assets/singularity-logo.png'
import { Link, useLocation } from 'react-router-dom'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward'
import { useTranslation } from 'react-i18next'

const Navbar: React.FC = () => {
  const location = useLocation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0, // Adjust this value as needed
  })

  const appBarStyle = {
    background: trigger ? '#000000' : 'transparent',
    transition: 'background 0.3s ease', // Add a transition property for smooth effect
    boxShadow: trigger ? '0px 3px 5px rgba(0, 0, 0, 0.1)' : 'none',
  }

  const mobileAppBarStyle = {
    background: trigger ? '#000000' : 'transparent',
    overflowX: 'auto',
    position: 'relative',
    left: 0,
    top: 0,
    maxWidth: '100%',
    transition: 'background 0.3s ease',
    boxShadow: trigger ? '0px 3px 5px rgba(0, 0, 0, 0.1)' : 'none',
    paddingTop: '1rem',
  }

  if (isMobile) {
    return (
      <React.Fragment>
        <AppBar sx={mobileAppBarStyle}>
          <Toolbar>
            <Link to={'/'}>
              <img src={Logo} alt="Logo" />
            </Link>
            <DrawerComp />
          </Toolbar>
        </AppBar>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <AppBar sx={appBarStyle}>
        <Toolbar>
          <Link to={'/'}>
            <img src={Logo} alt="Logo" />
          </Link>
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                justifyContent: 'center',
              }}
            >
              <nav
                style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}
              >
                {location.pathname.startsWith('/singularity-startup') ? (
                  <>
                    <Link
                      to="/singularity-startup/pricing"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        margin: '0 1rem',
                      }}
                    >
                      {t('singularity_link5')}
                    </Link>
                    <Link
                      to="/singularity-startup/process"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        margin: '0 1rem',
                      }}
                    >
                      {t('singularity_link6')}
                    </Link>
                    <Link
                      to="/contact"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        margin: '0 1rem',
                      }}
                    >
                      {t('singularity_link4')}
                    </Link>
                  </>
                ) : (
                  <>
                    <Link
                      to="/our-work"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        margin: '0 1rem',
                      }}
                    >
                      {t('singularity_link1')}
                    </Link>
                    <Link
                      to="/services"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        margin: '0 1rem',
                      }}
                    >
                      {t('singularity_link3')}
                    </Link>
                    <Link
                      to="/about"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        margin: '0 1rem',
                      }}
                    >
                      {t('singularity_link2')}
                    </Link>
                    <Link
                      to="/singularity-startup"
                      style={{
                        color: 'inherit',
                        textDecoration: 'none',
                        margin: '0 1rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {t('singularity_button1')}
                      <ArrowOutwardIcon style={{ marginLeft: '0.5rem' }} />
                    </Link>
                    <Link to="/contact" style={{ textDecoration: 'none' }}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: '#0076FF',
                          padding: '24px',
                          borderRadius: 0,
                          justifyContent: 'center',
                          maxHeight: '40px',
                          alignItems: 'center',
                          display: 'inline-flex',
                          textTransform: 'initial',
                        }}
                      >
                        {t('singularity_link4')}
                      </Button>
                    </Link>
                  </>
                )}
              </nav>
            </div>
            {location.pathname.startsWith('/singularity-startup') && (
              <Link to="/">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: '#0076FF',
                    padding: '24px',
                    borderRadius: 0,
                    maxHeight: '40px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'inline-flex',
                    textTransform: 'initial',
                  }}
                >
                  {t('singularity_button2')}
                  <ArrowOutwardIcon style={{ marginLeft: '0.5rem' }} />
                </Button>
              </Link>
            )}
          </>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}

export default Navbar
