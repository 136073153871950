import React from 'react'
import Grid from '@mui/material/Grid'
import HomepageLogoReact from '../../assets/homepage-logo-react.png'
import HomepageLogoAngular from '../../assets/homepage-logo-angular.png'
import HomepageLogoJava from '../../assets/homepage-logo-java.png'
import HomepageLogoSpring from '../../assets/homepage-logo-spring.png'
import CSHARP from '../../assets/c#.png'
import RUST from '../../assets/rust.png'
import DOCKER from '../../assets/docker.png'
import Kubernetes from '../../assets/kubernetes.png'
import Openshift from '../../assets/openshift.png'
import Aws from '../../assets/aws.png'
import Terraform from '../../assets/terraform.png'
import Typescript from '../../assets/typescript.png'
import Javascript from '../../assets/javascript.png'
import Vue from '../../assets/vue.png'
import Postgre from '../../assets/postgre.png'
import Kafka from '../../assets/kafka.png'
import Keycloak from '../../assets/keycloack.png'
import Sonarqube from '../../assets/sonarqube.png'

import '../../styles/Home/Icons.css'
import { useMediaQuery, useTheme } from '@mui/material'

interface IconsProps {
  customIcons?: Array<{
    id: number
    icon: string
    width?: number
    height?: number
  }>
}

const Icons: React.FC<IconsProps> = ({ customIcons }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const items = [
    { id: 3, icon: HomepageLogoJava, height: 40, width: null },
    { id: 6, icon: CSHARP, height: 40, width: null },
    { id: 7, icon: RUST, height: 40, width: null },
    { id: 4, icon: HomepageLogoSpring, height: 40, width: null },
    { id: 8, icon: DOCKER, height: 40, width: null },
    { id: 9, icon: Kubernetes, height: 40, width: null },
    { id: 10, icon: Openshift, height: 40, width: null },
    { id: 11, icon: Aws, height: 40, width: null },
    { id: 12, icon: Terraform, height: 40, width: null },
    { id: 13, icon: Typescript, height: 40, width: null },
    { id: 14, icon: Javascript, height: 40, width: null },
    { id: 1, icon: HomepageLogoReact, height: 40, width: null },
    { id: 2, icon: HomepageLogoAngular, height: 40, width: null },
    { id: 15, icon: Vue, height: 40, width: null },
    { id: 16, icon: Postgre, height: 40, width: null },
    { id: 17, icon: Kafka, height: 40, width: null },
    { id: 18, icon: Keycloak, height: 40, width: null },
    { id: 19, icon: Sonarqube, height: 40, width: null },
  ]

  return (
    <Grid container className={'icons_grid'} spacing={4}>
      {(customIcons ?? items).map((item) => (
        <Grid item key={item.id}>
          <img
            src={item.icon}
            alt="Icons"
            className={'icons_icon'}
            width={item.width ?? 'auto'}
            height={item.height}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default Icons
