import Slider from 'react-slick'
import { ProcessData } from './ProcessData'
import { useMediaQuery, useTheme } from '@mui/material'

const Process = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const settings = {
    infinite: false,
    arrows: false,
    slidesToShow: isMobile ? 1 : 2,
    speed: 500,
    // beforeChange: (current: number, next: number) => {
    //   setCurrentSlide(next)
    // },
  }
  return (
    <>
      <Slider {...settings}>
        {ProcessData.map((process, index) => (
          <div key={process.title}>
            <img
              src={process.img}
              alt={process.img}
              style={{
                maxWidth: isMobile ? '100%' : '440px',
                height: 'auto',
                backgroundColor: '#171717',
                marginBottom: '1rem',
              }}
              // height={312}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: '1rem',
              }}
            >
              <div
                style={{
                  width: '2.5rem',
                  height: '2.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#162EFF',
                  fontSize: '1.25rem',
                  lineHeight: '1.25rem',
                  paddingTop: '0.125rem',
                  boxSizing: 'border-box',
                }}
              >
                {index + 1}
              </div>
              <div
                style={{
                  border: '1px solid #171717',
                  flexGrow: 1,
                  height: 0,
                }}
              ></div>
            </div>
            <div style={{ maxWidth: '440px' }}>
              <h3
                style={{
                  fontSize: '2rem',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  fontWeight: '400',
                }}
              >
                {process.title}
              </h3>
              <p className="section_text" style={{ marginTop: '1rem' }}>
                {process.text}
              </p>
            </div>
          </div>
        ))}
      </Slider>
    </>
  )
}

export default Process
