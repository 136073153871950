import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useTranslation } from 'react-i18next'

const FAQ: React.FC<{ data: Array<{ title: string; text: string }> }> = ({
  data,
}) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <Grid
        container
        sx={{
          margin: 'auto',
        }}
        style={{ maxWidth: '800px' }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ paddingBottom: '4rem' }}
        >
          <h2 className={'services_heading'}>{t('home_screen_faq_heading')}</h2>
          <div className={'services_divider'}></div>
        </Grid>

        <Grid item xs={12}>
          {data.map((faq) => (
            <Accordion
              key={faq.title}
              disableGutters
              style={{
                marginBottom: '1rem',
                backgroundColor: '#171717',
                padding: '0.5rem',
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon fontSize="large" sx={{ color: '#0076FF' }} />
                }
              >
                <Typography
                  style={{
                    fontSize: isMobile ? '1rem' : '1.375rem',
                    fontWeight: 600,
                    color: '#FFF',
                  }}
                >
                  {faq.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ paddingBottom: '0.5rem', color: '#AAAAAA' }}
              >
                <Typography>{faq.text}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default FAQ
