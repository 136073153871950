import { Grid } from '@mui/material'
import { Element } from 'react-scroll'
import Challenges from './Challenges'
import Solution from './Solution'
import Value from './Value'
import Glacier from '../../../assets/iceberg.jpg'

const ChallengesList = [
  'glacier_melting_challenge_list_1',
  'glacier_melting_challenge_list_2',
  'glacier_melting_challenge_list_3',
]

const SolutionList = [
  'glacier_melting_solution_1',
  'glacier_melting_solution_2',
  'glacier_melting_solution_3',
  'glacier_melting_solution_4',
]

const ValueList = [
  'glacier_melting_value_1',
  'glacier_melting_value_2',
  'glacier_melting_value_3',
]

const GlacierMelting = () => {
  return (
    <>
      <Element name="glacier-melting">
        <h2
          className="section_heading"
          style={{ textAlign: 'center', marginTop: '5rem' }}
        >
          Monitoring Glacier Melting
        </h2>
        <div
          className={'section_heading_divider'}
          style={{
            margin: 'auto',
          }}
        ></div>
        <Grid container sx={{ marginTop: '4rem' }} spacing={4}>
          <Grid item xs={12} lg={6}>
            <img src={Glacier} alt={Glacier} style={{ maxWidth: '100%' }} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <p style={{ fontSize: '1rem', color: '#AAA', margin: 0 }}>
              The accelerating rate of glacier melting poses one of the most
              significant challenges to our planet, influencing marine
              ecosystems, altering ocean currents, and exacerbating the global
              climate crisis. Monitoring glacier dynamics is not only critical
              for understanding these impacts but also for developing mitigation
              and adaptation strategies.
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Challenges
              listStart="glacier_melting_challenge_start"
              text="glacier_melting_challenge_text"
              list={ChallengesList}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Solution
              text="glacier_melting_solution_text"
              solutionList={SolutionList}
            />
            <Value valueList={ValueList} />
          </Grid>
        </Grid>
      </Element>
    </>
  )
}

export default GlacierMelting
