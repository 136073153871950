import Design from '../../../assets/design-singularity.png'
import Database from '../../../assets/database-singularity.png'
import Architecture from '../../../assets/architecture-singularity.png'

export const OneApplication = [
  {
    name: 'Design',
    text: "With an easy wireframing and drag-and-drop editor, you can create a front-end that complements the AI's code in just a few minutes.",
    img: Design,
  },
  {
    name: 'Database',
    text: 'Choose the database of your preference directly within the system. After deploying your app, you will have direct access to your cloud space.',
    img: Database,
  },
  {
    name: 'Architecture',
    text: "You can rely on Singularity's architectural approach, and also customize it to your preferences. Enhance the app's architecture, tweak it, or completely change it to suit your needs.",
    img: Architecture,
  },
]
