import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProjectData } from './ProjectData'
import { Grid } from '@mui/material'
import ArrowForward from '@mui/icons-material/ArrowForward'
import '../../styles/Work/ProjectsOverview.css'
import { useTranslation } from 'react-i18next'
import ProjectCard from '../common/ProjectCard'
import ProjectFilter from './ProjectFilter'

const filters = [
  {
    key: 'all',
    value: '',
  },
  {
    key: 'development',
    value: 'development',
  },
  {
    key: 'start-up',
    value: 'start-up',
  },
  {
    key: 'research',
    value: 'research',
  },
]

const ProjectOverview: React.FC = () => {
  const { t } = useTranslation()
  const router = useNavigate()
  const [filter, setFilter] = useState<string>('')

  const changeFilter = useCallback(
    (filter: string) => {
      setFilter(filter)
    },
    [setFilter],
  )

  const handleProjectClick = (routerID: string) => {
    router(`/our-work/${routerID}`)
  }

  return (
    <>
      <Grid container justifyContent="center" alignItems="stretch" spacing={4}>
        <Grid item xs={12} sx={{ marginBottom: '1.75rem' }}>
          <ProjectFilter
            changeFilter={changeFilter}
            filters={filters}
            currentFilter={filter}
          />
        </Grid>
        {ProjectData.filter(
          (project) => filter.length === 0 || filter === project.badge,
        ).map((project) => (
          <Grid key={project.routeId} item lg={6} md={6} sm={12} xs={12}>
            <ProjectCard
              handleClick={handleProjectClick}
              logo={project.icon}
              id={project.routeId}
              badgeType={project.badge}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default ProjectOverview
