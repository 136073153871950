import React from 'react'
import Grid from '@mui/material/Grid'
import PageHeroBg from '../assets/page-hero-bg.webp'
import ContactUs from '../components/Home/ContactUs'
import Icons from '../components/Home/Icons'
import ServicesDetails from '../components/Services/ServicesDetails'
import Pricing from '../components/Services/Pricing'
import SingularityStartup from '../components/Home/SingularityStartup'
import '../styles/Services/Services.css'
import { Container, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Background from '../assets/startup-homepage-hero-bg.webp'

const Services: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Container>
        <Grid container spacing={12}>
          <Grid item xs={12}>
            <img src={PageHeroBg} alt="Logo" className={'services_image'} />
          </Grid>
          <Grid item xs={12}>
            <h2 className={'services_subheading'}>
              {t('services_screen_subheading')}
            </h2>
            <h1 className={'services_heading'}>
              {t('services_screen_heading')}
            </h1>
            <div className={'services_text'}>{t('services_screen_text')}</div>
          </Grid>
          <Grid item xs={12}>
            <Icons />
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12}>
        <ServicesDetails />
      </Grid>
      <Container>
        <Grid
          container
          spacing={12}
          sx={{ paddingBottom: '4rem', paddingTop: '4rem' }}
        >
          <Grid item xs={12}>
            <Pricing />
          </Grid>
          <Grid item xs={12} className="home_wrapper">
            <SingularityStartup />
          </Grid>
        </Grid>
      </Container>
      <div style={{ position: 'relative' }}>
        {' '}
        <img className="startup_image" src={Background} alt="Background" />
      </div>
      <Container>
        <Grid
          container
          spacing={12}
          sx={{ paddingBottom: '4rem', paddingTop: '4rem' }}
        >
          <Grid item xs={12}>
            <ContactUs />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Services
