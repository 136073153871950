import { Grid } from '@mui/material'
import PageHeroBg from '../../assets/page-hero-bg.webp'
import React from 'react'
import { t } from 'i18next'

interface PageHeaderProps {
  type?: string
  icon?: string
  title: string
  text: string
  badge?: string
}

const PageHeader: React.FC<PageHeaderProps> = ({
  icon,
  type,
  title,
  text,
  badge,
}) => {
  return (
    <>
      <Grid item xs={12}>
        <img src={PageHeroBg} alt="Logo" className={'work_image'} />
      </Grid>
      <Grid item xs={12} className={'work_grid'} justifyContent="center">
        {icon && <img src={icon} alt={icon} style={{ margin: 'auto' }} />}
        {!icon && type && <div className={'work_subheading'}>{t(type)}</div>}
        <h1 className={'work_heading'} style={{ marginTop: '2rem' }}>
          {t(title)}
        </h1>
        <div
          className={'work_text'}
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
        >
          {t(text)}
        </div>
        {badge && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                marginTop: '2rem',
                textAlign: 'center',
                fontSize: '0.75rem',
                padding: '0.5rem 1rem',
                backgroundColor: '#000000',
                borderRadius: '100px',
                boxSizing: 'border-box',
                fontWeight: 600,
              }}
            >
              <div style={{ marginTop: '2px' }}>{t(`${badge}_badge`)}</div>
            </div>
          </div>
        )}
      </Grid>
    </>
  )
}

export default PageHeader
