import { Container, Grid, useMediaQuery, useTheme } from '@mui/material'
import PageHeader from '../PageHeader'
import { Project } from '../ProjectData'
import { t } from 'i18next'
import ProjectSection from './ProjectSection'
import DiscoverProjects from '../DiscoverProjects'
import ContactUs from '../../Home/ContactUs'

const ProjectDetail: React.FC<Project> = ({ ...props }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <Container>
        <Grid container spacing={12}>
          <PageHeader
            icon={props.icon}
            title={props.heading}
            text={props.subHeading}
            badge={props.badge}
          />
        </Grid>
      </Container>
      <div style={{ backgroundColor: '#0F0F0F' }}>
        <Grid
          container
          className={'details_grid_container'}
          sx={{
            maxWidth: '1152px',
            margin: 'auto',
          }}
        >
          <Grid item className={'details_grid_item'} xs={12}>
            <div className={'details_content_heading'}>
              {t('project_details_challenge_heading')}
            </div>
            <div className={'details_content_divider'}></div>
            <div className={'details_content_subheading'}>
              {props.challenge}
            </div>
          </Grid>
        </Grid>
      </div>
      <Container>
        <Grid
          container
          spacing={12}
          sx={{ marginBottom: '4rem', marginTop: 0 }}
        >
          <ProjectSection
            title="project_details_idea_heading"
            text={props.idea}
            img={props.ideaImage}
          />
          <ProjectSection
            title="project_details_solution_heading"
            text={props.solution}
            img={props.solutionImage}
          />
          <Grid item xs={12}>
            <DiscoverProjects currentProject={props.routeId} />
          </Grid>
          <Grid item xs={12}>
            <ContactUs />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default ProjectDetail
