import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ProjectData } from './ProjectData'
import '../../styles/Work/ProjectsDetails.css'
import ProjectDetail from './ProjectDetail/ProjectDetail'
import WeatherForecast from './WeatherForecast/WeatherForecast'
import SingularityProject from './SingularityProject/SingularityProject'

const ProjectDetails: React.FC = () => {
  const { routeId } = useParams<{ routeId?: string }>()
  const project = useMemo(() => {
    if (routeId) {
      return ProjectData.find((project) => project.routeId === routeId)
    } else {
      return null
    }
  }, [routeId])

  useEffect(() => {}, [routeId])

  if (!project) {
    return <>Loading</>
  }

  if (routeId === 'weather-forecast') {
    return <WeatherForecast />
  }

  if (routeId === 'singularity-ai') {
    return <SingularityProject />
  }

  return <ProjectDetail {...project} />
}

export default ProjectDetails
