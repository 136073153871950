import ValueIcon from '../../../assets/value.png'
import { t } from 'i18next'

interface ValueProps {
  valueList: string[]
}

const Value: React.FC<ValueProps> = ({ valueList }) => {
  return (
    <>
      <h3
        style={{
          fontSize: '2rem',
          fontWeight: 400,
          marginTop: 0,
          marginBottom: '1rem',
        }}
      >
        Value
      </h3>
      {valueList.map((value) => (
        <div key={value}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '1.5rem' }}>
            <img src={ValueIcon} alt={ValueIcon} width={38} height={38} />
            <h3 style={{ fontSize: '1rem', color: '#AAA', paddingTop: '2px' }}>
              {t(value)}
            </h3>
          </div>
          <p style={{ color: '#AAA' }}>{t(`${value}_text`)}</p>
        </div>
      ))}
    </>
  )
}

export default Value
