import { Grid } from '@mui/material'
import { t } from 'i18next'

interface ProjectSectionProps {
  title: string
  text: string
  img?: string
}

const ProjectSection: React.FC<ProjectSectionProps> = ({
  title,
  text,
  img,
}) => {
  return (
    <>
      <Grid item xs={12} md={6}>
        <h2 className="section_heading">{t(title)}</h2>
        <div className="section_heading_divider"></div>
      </Grid>
      <Grid item xs={12} md={6}>
        <p className="section_text" style={{ margin: 0 }}>
          {text}
        </p>
      </Grid>
      {img && (
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <img src={img} alt={img} style={{ maxWidth: '100%' }} />
        </Grid>
      )}
    </>
  )
}

export default ProjectSection
