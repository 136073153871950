import { t } from 'i18next'

interface BadgeProps {
  badgeType: string
}

const Badge: React.FC<BadgeProps> = ({ badgeType }) => {
  return (
    <div className={'badge'}>
      <div style={{ marginTop: '2px' }}>{t(`${badgeType}_badge`)}</div>
    </div>
  )
}

export default Badge
