import { Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import Design from '../../../assets/slide-design.png'
import Backend from '../../../assets/slide-backend.png'
import Architecture from '../../../assets/slide-architecture.png'
import Deploy from '../../../assets/slide-deploy.png'
import Collaborate from '../../../assets/slide-colaborate.png'

const Sections = [
  {
    name: 'Design',
    text: 'An easy wireframing and drag-and-drop editor enables you to create a front-end in just a few minutes.',
    image: Design,
  },
  {
    name: 'Database',
    text: 'Choose your preferred database directly within the system and gain direct access to your cloud space after deploying your app.',
    image: Backend,
  },
  {
    name: 'Architecture',
    text: "Have complete control over your application's cloud architecture and software delivery. Adjust remote servers and set up restrictions for incoming and outgoing traffic.",
    image: Architecture,
  },
  {
    name: 'Deployment',
    text: 'Deploy your app with a single click and launch it to the market.',
    image: Deploy,
  },
  {
    name: 'Collaboration',
    text: 'Invite team members or external contributors to your Singularity project, assign roles, and set access restrictions for seamless collaboration.',
    image: Collaborate,
  },
]

const ApplicationDescription = () => {
  const [activeTab, setActiveTab] = useState('Design')
  const [activeImage, setActiveImage] = useState<string | null>(null)

  useEffect(() => {
    const image =
      Sections.find((data) => data.name === activeTab)?.image ?? null
    setActiveImage(image)
  }, [activeTab])
  const handleClick = (tab: string) => {
    setActiveTab(tab)
  }
  return (
    <Grid
      container
      spacing={4}
      sx={{ marginBottom: '60px', marginX: 0, width: '100%' }}
    >
      <Grid item xs={12} lg={4}>
        {Sections.map((section) => (
          <div
            key={section.name}
            onClick={() => handleClick(section.name)}
            style={{
              borderLeft: activeTab === section.name ? '2px solid #0076FF' : '',
              padding: '1rem',
              cursor: 'pointer',
            }}
          >
            <h4 style={{ margin: 0, fontSize: '22px', lineHeight: '22px' }}>
              {section.name}
            </h4>
            {activeTab === section.name && (
              <p
                style={{
                  marginBottom: 0,
                  marginTop: '1rem',
                  fontSize: '0.875rem',
                  fontWeight: 500,
                  color: '#AAA',
                }}
              >
                {section.text}
              </p>
            )}
          </div>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        lg={8}
        sx={{
          backgroundColor: '#131313',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {activeImage && <img src={activeImage} alt={activeImage} />}
      </Grid>
    </Grid>
  )
}

export default ApplicationDescription
