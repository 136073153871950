import { t } from 'i18next'

interface ChallengesProps {
  listStart: string
  list: string[]
  text: string
}

const Challenges: React.FC<ChallengesProps> = ({ listStart, list, text }) => {
  return (
    <>
      <h3
        style={{
          fontSize: '2rem',
          fontWeight: 400,
          marginTop: 0,
          marginBottom: '1rem',
        }}
      >
        Challenges
      </h3>
      <p style={{ color: '#AAA' }}>{t(listStart)}</p>
      <ul>
        {list.map((challenge, index) => (
          <li
            key={index}
            style={{ color: '#AAA' }}
            dangerouslySetInnerHTML={{ __html: t(challenge) }}
          ></li>
        ))}
      </ul>
      <p style={{ color: '#AAA' }}>{t(text)}</p>
    </>
  )
}

export default Challenges
