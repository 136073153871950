import React from 'react'
import { Button, Card, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Background from '../assets/startup-homepage-hero-bg.webp'
import SingularityStartupDataView from '../components/SingularityStartup/SingularityStartupDataView'
import SingularityStartupPromo from '../components/SingularityStartup/SingularityStartupPromo'
import ContactUs from '../components/Home/ContactUs'
import Icons from '../components/Home/Icons'
import CustomDialog from '../components/CustomDialog'
import { useTranslation } from 'react-i18next'
import SingularityProject from '../components/Work/SingularityProject/SingularityProject'

const SingularityStartupPage: React.FC = () => {
  const { t } = useTranslation()
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return <SingularityProject />

  if (isMobile) {
    return (
      <>
        <Box sx={{ display: 'grid' }}>
          <img
            src={Background}
            alt="Background"
            style={{
              position: 'absolute',
              left: 0,
              height: '80%',
              width: '100%',
              zIndex: -1,
            }}
          />
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '6rem',
              }}
            >
              <h1
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                  fontSize: '38px',
                  fontWeight: '400',
                  fontStyle: 'normal',
                  lineHeight: '48px',
                  textAlign: 'center',
                  color: '#FFF',
                  whiteSpace: 'pre-line',
                }}
              >
                {t('singularity_startup_main_heading')}
              </h1>
              <div
                style={{
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '300',
                  lineHeight: '24px',
                  marginTop: '1rem',
                  textAlign: 'center',
                  color: '#AAA',
                  lineBreak: 'auto',
                  maxWidth: '50ch',
                }}
              >
                {t('singularity_startup_main_subheading')}
              </div>
              <Button
                onClick={() => setOpen(true)}
                variant="contained"
                sx={{
                  padding: '20px 24px',
                  textAlign: 'center',
                  marginTop: '2rem',
                  borderRadius: '0px',
                  color: '#FFF',
                  textTransform: 'initial',
                  backgroundColor: '#0076FF',
                }}
              >
                {t('singularity_startup_main_button')}
              </Button>
            </Grid>
          </Grid>
        </Box>
        <SingularityStartupDataView />
        <div style={{ marginTop: '2rem' }}></div>
        <SingularityStartupPromo />
        <div style={{ marginTop: '6rem' }}></div>
        <div style={{ margin: '1rem' }}>
          <ContactUs />
        </div>
        {isMobile ? <div></div> : <Icons />}

        <CustomDialog open={open} onClose={() => setOpen(false)} />
      </>
    )
  }

  return (
    <>
      <Box sx={{ display: 'flex', height: '50vh' }}>
        <img
          src={Background}
          alt="Background"
          style={{
            position: 'absolute',
            left: 0,
            height: '80%',
            width: '100%',
            zIndex: -1,
          }}
        />
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: '10rem',
            }}
          >
            <h1
              style={{
                marginTop: '0px',
                marginBottom: '0px',
                fontSize: '64px',
                fontWeight: '400',
                fontStyle: 'normal',
                lineHeight: '80px',
                textAlign: 'center',
                color: '#FFF',
                whiteSpace: 'pre-line',
              }}
            >
              {t('singularity_startup_main_heading')}
            </h1>
            <div
              style={{
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '300',
                lineHeight: '24px',
                marginTop: '2rem',
                textAlign: 'center',
                color: '#AAA',
                lineBreak: 'auto',
                maxWidth: '50ch',
              }}
            >
              {t('singularity_startup_main_subheading')}
            </div>
            <Button
              onClick={() => setOpen(true)}
              variant="contained"
              sx={{
                padding: '20px 24px',
                textTransform: 'initial',
                backgroundColor: '#0076FF',
                textAlign: 'center',
                marginTop: '2rem',
                borderRadius: '0px',
                color: '#FFF',
              }}
            >
              {t('singularity_startup_main_button')}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <SingularityStartupDataView />
      <div style={{ marginTop: '2rem' }}></div>
      <SingularityStartupPromo />
      <div style={{ marginTop: '40rem' }}></div>
      <div style={{ margin: '2rem 6rem' }}>
        <ContactUs />
      </div>
      <div style={{ margin: '2rem 6rem' }}>
        {isMobile ? <div></div> : <Icons />}
      </div>

      <CustomDialog open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default SingularityStartupPage
