import React from 'react'
import Grid from '@mui/material/Grid'
import { ServiceData } from './ServicesData'
import { useMediaQuery, useTheme } from '@mui/material'

const ServicesDetails: React.FC = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Grid container sx={{ display: 'flex', flexDirection: 'column' }}>
        {ServiceData.map((service, index) => (
          <Grid
            container
            key={index}
            sx={{
              paddingTop: '2rem',
              paddingBottom: '2rem',
              background: {
                md:
                  index === 0
                    ? 'linear-gradient(to bottom, transparent, #080808)'
                    : index % 2 === 0
                    ? '#080808'
                    : '#0F0F0F',
              },
            }}
          >
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  maxWidth: '1152px',
                  margin: 'auto',
                  paddingLeft: '1rem',
                  paddingRight: '1rem',
                  flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h3
                    style={{
                      fontSize: isMobile ? '32px' : '48px',
                      fontWeight: '400',
                      fontStyle: 'normal',
                      lineHeight: isMobile ? '130%' : '60px',
                      color: '#FFF',
                      whiteSpace: 'pre-line',
                      textAlign: 'start',
                      marginTop: isMobile ? 0 : '3rem',
                      marginBottom: isMobile ? 0 : '3rem',
                      marginRight: isMobile
                        ? '0'
                        : index === 0
                        ? '4rem'
                        : index === 2
                        ? '4.5rem'
                        : index === 4
                        ? '8rem'
                        : '',
                    }}
                  >
                    {service.heading}
                  </h3>
                  <div
                    style={{
                      backgroundColor: '#0076FF',
                      height: '2px',
                      marginTop: isMobile ? '1rem' : '-2rem',
                      width: '64px',
                      textAlign: 'start',
                      marginRight: isMobile
                        ? '0'
                        : index === 0
                        ? '19.5rem'
                        : index === 2
                        ? '19.2rem'
                        : index === 4
                        ? '20rem'
                        : '',
                    }}
                  ></div>
                  <p
                    style={{
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '300',
                      lineHeight: '24px',
                      marginTop: '2rem',
                      color: '#AAA',
                      whiteSpace: 'pre-line',
                      textAlign: 'start',
                    }}
                  >
                    {service.text}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: {
                      xs: 'center',
                      // md: index % 2 === 0 ? 'flex-start' : 'flex-end',
                    },
                    justifyContent: 'center',
                    padding: { xs: 2 },
                  }}
                >
                  <img
                    src={service.background}
                    alt={service.background}
                    style={{
                      width: '50%',
                      marginTop: 0,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </>
  )
}

export default ServicesDetails
