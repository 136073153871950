// TODO: add images colors
import React, { useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'
import '../../styles/Home/OurWork.css'
import { useTranslation } from 'react-i18next'
import { ProjectData } from '../Work/ProjectData'
import ProjectCard from '../common/ProjectCard'
import Slider from 'react-slick'

const OurWork: React.FC = () => {
  const router = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    infinite: false,
    arrows: false,
    slidesToShow: isMobile ? 1 : 2,
    speed: 500,
    beforeChange: (current: number, next: number) => {
      setCurrentSlide(next)
    },
  }

  const handleClick = (routerID: string) => {
    router(`/our-work/${routerID}`)
  }

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={6} sm={6} xs={12} className={'ourwork_container'}>
          <h3 className={'ourwork_heading'}>
            {t('home_screen_our_work_heading')}
          </h3>
          <div className={'ourwork_blue_line'}></div>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} className={'ourwork_button'}>
          <div className={'ourwork_text'}>
            {t('home_screen_our_work_subheading')}
          </div>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} sx={{ marginTop: '4rem' }}>
          <Slider {...settings}>
            {ProjectData.map((project, index) => {
              return (
                <ProjectCard
                  key={project.routeId}
                  handleClick={handleClick}
                  logo={project.icon}
                  id={project.routeId}
                  badgeType={project.badge}
                  isMobile={isMobile}
                  index={index}
                />
              )
            })}
          </Slider>
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '2rem' }}>
          <div
            style={{
              height: '2px',
              width: '100%',
              backgroundColor: '#D9D9D9',
              position: 'relative',
            }}
          >
            <div
              style={{
                height: '100%',
                width: `${
                  ((currentSlide + 1) /
                    (isMobile ? ProjectData.length : ProjectData.length - 1)) *
                  100
                }%`,
                backgroundColor: '#0076FF', // You can change the color
                transition: 'width 0.5s ease-in-out',
              }}
            ></div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default OurWork
