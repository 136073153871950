import React from 'react'
import SingularityStartupProcessView from '../components/SingularityStartup/SingularityStartupProcessView'
import ContactUs from '../components/Home/ContactUs'

const SingularityStartupProcess: React.FC = () => {
  return (
    <>
      <SingularityStartupProcessView />
      <div style={{ margin: '2rem 6rem' }}>
        <ContactUs />
      </div>
    </>
  )
}

export default SingularityStartupProcess
