import { Button, Grid } from '@mui/material'
import { t } from 'i18next'

interface HighlightActionProps {
  transparent?: boolean
  title: string
  description: string
  buttonText: string
}

const HighlightAction: React.FC<HighlightActionProps> = ({
  title,
  description,
  buttonText,
  transparent,
}) => {
  const handleButtonClick = () => {
    const url =
      'https://calendly.com/sales-9gm/consultation-with-singularity-agency/consultation-for-singularity-agency'
    window.open(url, '_blank')
  }
  return (
    <Grid
      item
      xs={12}
      sx={{
        minHeight: '400px',
        backgroundColor: transparent ? 'transparent' : '#0076FF',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '4rem',
      }}
    >
      <h2
        className="section_heading"
        style={{ textAlign: 'center', fontSize: '2.5rem' }}
      >
        {t(title)}
      </h2>
      <div
        className={'section_heading_divider'}
        style={{
          backgroundColor: '#FFF',
          textAlign: 'center',
          opacity: '50%',
          minHeight: '2px',
        }}
      ></div>
      <p
        style={{
          marginTop: '2rem',
          marginBottom: '2rem',
          fontSize: '1.5rem',
          textAlign: 'center',
        }}
      >
        {t(description)}
      </p>
      <Button
        onClick={handleButtonClick}
        variant="contained"
        sx={{
          padding: '20px 24px',
          textAlign: 'center',
          borderRadius: '0px',
          fontWeight: 900,
          fontSize: '0.875rem',
          color: '#FFF',
          textTransform: 'initial',
          backgroundColor: transparent ? '#0076FF' : '#000',
        }}
      >
        {t(buttonText)}
      </Button>
    </Grid>
  )
}

export default HighlightAction
