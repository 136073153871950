import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface ProjectFilterProps {
  changeFilter: (filter: string) => void
  filters: { key: string; value: string }[]
  currentFilter: string
}
const ProjectFilter: React.FC<ProjectFilterProps> = ({
  changeFilter,
  currentFilter,
  filters,
}) => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        display: 'flex',
        gap: '1.25rem',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
    >
      {filters.map((filter) => (
        <div
          key={filter.key}
          style={{ display: 'inline', marginBottom: '1rem' }}
        >
          <Chip
            label={t(`filter_${filter.key}`)}
            onClick={() => changeFilter(filter.value)}
            color="info"
            sx={{
              color: currentFilter === filter.value ? '#080808' : '#AAAAAA',
              backgroundColor:
                currentFilter === filter.value
                  ? '#FFF'
                  : 'rgba(255, 255, 255, 0.08)',
              padding: '1rem 1.25rem',
              fontSize: '0.875rem',
              lineHeight: '1.5rem',
              fontWeight: 600,
              display: 'inline',
              borderRadius: '100px',
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default ProjectFilter
