import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import {
  Typography,
  FormControl,
  Card,
  Select,
  MenuItem,
  useTheme,
  useMediaQuery,
} from '@mui/material'
import Box from '@mui/material/Box'
import PageHeroBg from '../assets/page-hero-bg.webp'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import styled from '@emotion/styled'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import CountryCodes from '../assets/data/CountryCodes.json'
import axios from 'axios'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'

const ContactUs: React.FC = () => {
  const { t } = useTranslation()
  const [name, setName] = useState<string>('')
  const [errorName, setErrorName] = useState<boolean>(false)
  const [email, setEmail] = useState<string>('')
  const [errorEmail, setErrorEmail] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<boolean>(false)
  const [phoneNumber, setPhoneNumber] = useState<string>('+421')
  const [countryCode, setCountryCode] = useState<string>('+421')
  const [phoneError, setPhoneError] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [email_response, setEmail_response] = useState<string>('')
  const [email_status, setEmail_status] = useState<number>(0)

  const [captcha, setCaptcha] = useState<boolean>(false)

  const [singularity_type, setSingularity_type] = useState<string>('')

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const textFieldStyles = {
    color: 'white',
    backgroundColor: 'black',
    '&:hover fieldset': {
      borderColor: 'white', // Change border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white', // Change border color when focused
    },
    '&.custom_outline': {
      borderWidth: 2, // Customize the border width for the custom outline
    },
    '&.error_outline': {
      borderColor: 'red', // Change border color for error outline
    },
  }

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    setShowError(false)
  }

  const handleEmailClick = () => {
    window.scrollTo(0, 0)
  }

  function isValidName(input: string) {
    const validNameRegex = /^[A-Za-z-' ]+$/
    const trimmedInput = input.trim()
    return validNameRegex.test(trimmedInput)
  }

  const handleNameChange = (input: string) => {
    setName(input)
    const validName = isValidName(input)

    if (!validName) {
      setErrorName(true)
      return
    }

    setErrorName(false)
  }

  const handleEmailChange = (input: string) => {
    setEmail(input)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

    const validEmail = emailRegex.test(input)

    if (!validEmail) {
      setErrorEmail(true)
      return
    }

    setErrorEmail(false)
  }

  const handleSingularityTypeChange = (input: string) => {
    setSingularity_type(input)
  }

  const handleMessageChange = (text: string) => {
    setMessage(text)
    const minLengthRegex = /^.{20,}$/
    const validMessage = minLengthRegex.test(text)

    if (!validMessage) {
      setErrorMessage(true)
      return
    }

    setErrorMessage(false)
  }

  const handlePhoneCountryChange = (data: any) => {
    setCountryCode(data.value)
    setPhoneNumber(data.value)
  }

  const handlePhoneNumberChange = (data: any) => {
    setPhoneNumber(data)
    const regex = /^[+0-9]+$/
    const validNumber = regex.test(data)

    if (!validNumber) {
      setPhoneError(true)
      return
    }

    setPhoneError(false)
  }

  const sendEmail = async () => {
    const recipient_email = 'sales@globalsingularity.io'

    const url = 'https://api.elasticemail.com/v4/emails'
    const params = {
      apiKey:
        'B95470DEE8CE8BD1672B948DE50CBC6189ED4C4DF5BBA5D7ADAF93540D1E4CC43CACFE6A5B56A0DE296D1A9C50772D00',
    }
    const body = {
      Recipients: [
        {
          Email: recipient_email,
        },
      ],
      Content: {
        Body: [
          {
            ContentType: 'HTML',
            Content: 'string',
            Charset: 'string',
          },
        ],
        Merge: {
          message: message,
          full_name: name,
          phone_number: phoneNumber,
          service_type: singularity_type,
        },
        Postback: 'string',
        EnvelopeFrom: email,
        From: email,
        ReplyTo: email,
        Subject: 'Global Singularity Contact Form',
        TemplateName: 'SingularitySalesMailTemplate',
      },
    }

    var json_response = {
      status: 0,
      message: '',
    }

    try {
      const response = await axios.post(url, body, { params })
      json_response.status = 200
      json_response.message = 'Email sent successfully'
      return json_response
    } catch (error) {
      json_response.status = 400
      json_response.message = `There was an error sending the email: ${error}`
      return json_response
    }
  }

  const handleSubmit = async () => {
    let formIsValid = true
    if (!checked) {
      setShowError(true)
      formIsValid = false
    }

    if (name.length === 0) {
      setErrorName(true)
      formIsValid = false
    }

    if (email.length === 0) {
      setErrorEmail(true)
      formIsValid = false
    }

    if (message.length === 0) {
      setErrorMessage(true)
      formIsValid = false
    }

    if (formIsValid) {
      const response = await sendEmail()
      setEmail_response(response.message)
      setEmail_status(response.status)
      setName('')
      setEmail('')
      setSingularity_type('startup')
      setCountryCode('')
      setPhoneNumber('')
      setMessage('')
      setChecked(false)
    }
  }
  const verifyCaptcha = async (response: any) => {
    const secretKey = '6Lf81cMnAAAAABJaZGV379a70f5njduAEk6SMmq3'
    setCaptcha(true)

    try {
      const verificationResponse = await axios.post(
        `https://www.google.com/recaptcha/api/siteverify?secret=${secretKey}&response=${response}`,
      )
      const data = verificationResponse.data

      if (data.success) {
        console.log('Captcha verified on client-side')
        // setCaptcha(true);
      } else {
        console.log('Failed to verify captcha on client-side')
      }
    } catch (error) {
      console.log('Verification error', error)
    }
  }

  const CustomCheckbox = styled(Checkbox)`
    &.MuiCheckbox-root {
      color: white; // This is for the default (unchecked) color

      &.Mui-checked {
        color: white; // This is for the checked color
      }

      &:hover {
        color: white; // This is for the hover color
      }
    }
  `

  const CustomFormControl = styled(FormControl)`
    & .MuiOutlinedInput-root {
      color: white;
      background-color: black;

      & .MuiOutlinedInput-notchedOutline {
        border-color: none;
      }

      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: white;
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: white;
      }

      & .MuiSelect-icon {
        color: white; // This targets the dropdown icon.
      }
    }
  `

  if (isMobile) {
    return (
      <Box>
        <img
          src={PageHeroBg}
          alt="Logo"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: -1,
          }}
        />
        <Grid container style={{ marginBottom: '8rem' }}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '2rem',
            }}
          >
            <div
              style={{
                fontSize: '18px',
                fontWeight: '400',
                textAlign: 'center',
                color: '#0076FF',
              }}
            >
              {t('contact_subheading')}
            </div>
            <h1
              style={{
                marginTop: '0px',
                marginBottom: '0px',
                fontSize: '32px',
                fontWeight: '400',
                fontStyle: 'normal',
                lineHeight: '130%',
                textAlign: 'center',
                color: '#FFF',
                whiteSpace: 'pre-line',
              }}
            >
              {t('contact_heading')}
            </h1>

            <Card
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                margin: '2rem 1rem 0 1rem',
                padding: '1rem',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      textAlign: 'center',
                      fontSize: '16px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: '110%',
                      color: 'white',
                    }}
                  >
                    {t('contact_form_heading')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: 'white',
                    }}
                  >
                    {t('contact_form_name')}
                  </Typography>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& > fieldset': { borderColor: 'none' },
                      },
                      '& .MuiOutlinedInput-root.Mui-focused': {
                        '& > fieldset': {
                          borderColor: 'white',
                        },
                      },
                      '& .MuiOutlinedInput-root:hover': {
                        '& > fieldset': {
                          borderColor: 'white',
                        },
                      },
                    }}
                    InputProps={{
                      style: textFieldStyles,
                    }}
                    value={name}
                    onChange={(e) => handleNameChange(e.target.value)}
                    style={{ marginTop: '.5rem' }}
                    type="text"
                    required
                    variant="outlined"
                    fullWidth
                    error={errorName}
                    helperText={errorName ? 'Invalid name' : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: 'white',
                    }}
                  >
                    {t('contact_form_email')}
                  </Typography>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& > fieldset': { borderColor: 'none' },
                      },
                      '& .MuiOutlinedInput-root.Mui-focused': {
                        '& > fieldset': {
                          borderColor: 'white',
                        },
                      },
                      '& .MuiOutlinedInput-root:hover': {
                        '& > fieldset': {
                          borderColor: 'white',
                        },
                      },
                    }}
                    InputProps={{
                      style: textFieldStyles,
                    }}
                    value={email}
                    onChange={(e) => handleEmailChange(e.target.value)}
                    style={{ marginTop: '.5rem' }}
                    type="email"
                    required
                    variant="outlined"
                    fullWidth
                    error={errorEmail}
                    helperText={errorEmail ? 'Invalid email address' : ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      style={{
                        fontSize: '14px',
                        fontWeight: '300',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: 'white',
                      }}
                    >
                      {t('contact_form_phone_number')}
                    </Typography>
                    <Typography
                      style={{
                        textAlign: 'end',
                        fontSize: '14px',
                        fontWeight: '300',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: 'white',
                      }}
                    >
                      {t('contact_form_optional')}
                    </Typography>
                  </div>

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <CustomFormControl
                      variant="outlined"
                      style={{
                        marginTop: '.5rem',
                        marginRight: '.5rem',
                        width: '8rem',
                      }}
                    >
                      <Select
                        value={countryCode}
                        onChange={(e) =>
                          handlePhoneCountryChange({ value: e.target.value })
                        }
                      >
                        {CountryCodes.map((country, index) => (
                          <MenuItem key={index} value={country.dial_code}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </CustomFormControl>
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& > fieldset': { borderColor: 'none' },
                        },
                        '& .MuiOutlinedInput-root.Mui-focused': {
                          '& > fieldset': {
                            borderColor: 'white',
                          },
                        },
                        '& .MuiOutlinedInput-root:hover': {
                          '& > fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }}
                      InputProps={{
                        style: textFieldStyles,
                      }}
                      value={phoneNumber}
                      onChange={(e) => handlePhoneNumberChange(e.target.value)}
                      style={{ marginTop: '.5rem' }}
                      type="text"
                      variant="outlined"
                      fullWidth
                      error={phoneError}
                      helperText={phoneError ? 'Please enter only numbers' : ''}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: 'white',
                    }}
                  >
                    {t('contact_form_service')}
                  </Typography>
                  <CustomFormControl
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: '.5rem' }}
                  >
                    <Select
                      value={singularity_type}
                      defaultValue={'Singularity Startup'}
                      onChange={(e) =>
                        handleSingularityTypeChange(e.target.value)
                      }
                    >
                      <MenuItem value={'Singularity Startup'}>
                        Singularity Startup
                      </MenuItem>
                      <MenuItem value={'Singularity Agency'}>
                        Singularity Agency
                      </MenuItem>
                    </Select>
                  </CustomFormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: 'white',
                    }}
                  >
                    {t('contact_form_text_area')}
                  </Typography>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& > fieldset': { borderColor: 'none' },
                      },
                      '& .MuiOutlinedInput-root.Mui-focused': {
                        '& > fieldset': {
                          borderColor: 'white',
                        },
                      },
                      '& .MuiOutlinedInput-root:hover': {
                        '& > fieldset': {
                          borderColor: 'white',
                        },
                      },
                    }}
                    InputProps={{
                      style: textFieldStyles,
                    }}
                    value={message}
                    onChange={(e) => handleMessageChange(e.target.value)}
                    style={{ marginTop: '.5rem' }}
                    type="text"
                    required
                    placeholder={t('contact_form_text_area_placeholder')}
                    fullWidth
                    rows={8}
                    variant="outlined"
                    multiline
                    error={errorMessage}
                    helperText={errorMessage ? 'Minimum is 20 characters' : ''}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <CustomCheckbox
                    color="secondary"
                    checked={checked}
                    onChange={handleCheckBoxChange}
                    style={{ marginRight: '1rem' }}
                  />
                  <Typography
                    style={{
                      marginTop: '.8rem',
                      fontSize: '14px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: 'white',
                    }}
                  >
                    {t('contact_form_privacy_policy')}
                  </Typography>
                  {showError && (
                    <Typography
                      variant="body2"
                      color="error"
                      marginTop=".55rem"
                      marginLeft="auto"
                    >
                      You must agree to the terms and conditions.
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ReCAPTCHA
                    sitekey="6Lf81cMnAAAAAK4_Dxo-7BOV2La9-BTN7ha-DLVO"
                    onChange={verifyCaptcha}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <Button
                    disabled={!captcha}
                    onClick={handleSubmit}
                    style={{
                      padding: '20px 48px',
                      textDecoration: 'none',
                      backgroundColor: '#0076FF',
                      color: 'white',
                      borderRadius: 0,
                      fontSize: '14px',
                      fontWeight: 300,
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      letterSpacing: '0.56px',
                      textTransform: 'initial',
                    }}
                  >
                    {t('contact_form_send_button')}
                  </Button>
                  {email_response && (
                    <Typography
                      variant="body2"
                      color={email_status === 200 ? 'green' : 'red'}
                      marginTop=".55rem"
                      marginLeft="auto"
                    >
                      {email_response}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Card>
            <div style={{ marginTop: '6rem' }}>
              <h2
                style={{
                  fontSize: '24px',
                  fontWeight: '300',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  textAlign: 'center',
                  color: '#FFF',
                  whiteSpace: 'pre-line',
                }}
              >
                {t('contact_form_email_us_text')}
              </h2>
              <div
                onClick={handleEmailClick}
                style={{
                  fontSize: '24px',
                  fontWeight: '300',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  textAlign: 'center',
                  color: '#0076FF',
                  display: 'flex',
                  marginTop: '2rem',
                  cursor: 'pointer',
                }}
              >
                <MailOutlineIcon
                  style={{
                    color: '#0076FF',
                    verticalAlign: 'middle',
                    marginTop: '.3rem',
                  }}
                />
                <span style={{ marginLeft: '1rem' }}>
                  sales@globalsingularity.io
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <>
      <Box>
        <img
          src={PageHeroBg}
          alt="Logo"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: -1,
          }}
        />
        <Grid container style={{ marginBottom: '8rem' }}>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '8rem',
            }}
          >
            <div
              style={{
                fontSize: '20px',
                fontWeight: '400',
                fontStyle: 'normal',
                lineHeight: 'normal',
                textAlign: 'center',
                color: '#0076FF',
              }}
            >
              {t('contact_subheading')}
            </div>
            <h1
              style={{
                marginTop: '0px',
                marginBottom: '0px',
                fontSize: '64px',
                fontWeight: '400',
                fontStyle: 'normal',
                lineHeight: '80px',
                textAlign: 'center',
                color: '#FFF',
                whiteSpace: 'pre-line',
                paddingBottom: '6rem',
              }}
            >
              {t('contact_heading')}
            </h1>

            <Card
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
                margin: '4rem 12rem 0 12rem',
                padding: '2rem',
                maxWidth: '896px',
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      textAlign: 'center',
                      fontSize: '24px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: 'white',
                    }}
                  >
                    {t('contact_form_heading')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: 'white',
                    }}
                  >
                    {t('contact_form_name')}
                  </Typography>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& > fieldset': { borderColor: 'none' },
                      },
                      '& .MuiOutlinedInput-root.Mui-focused': {
                        '& > fieldset': {
                          borderColor: 'white',
                        },
                      },
                      '& .MuiOutlinedInput-root:hover': {
                        '& > fieldset': {
                          borderColor: 'white',
                        },
                      },
                    }}
                    InputProps={{
                      style: textFieldStyles,
                    }}
                    value={name}
                    onChange={(e) => handleNameChange(e.target.value)}
                    style={{ marginTop: '.5rem' }}
                    type="text"
                    required
                    variant="outlined"
                    fullWidth
                    error={errorName}
                    helperText={errorName ? 'Invalid name' : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: 'white',
                    }}
                  >
                    {t('contact_form_email')}
                  </Typography>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& > fieldset': { borderColor: 'none' },
                      },
                      '& .MuiOutlinedInput-root.Mui-focused': {
                        '& > fieldset': {
                          borderColor: 'white',
                        },
                      },
                      '& .MuiOutlinedInput-root:hover': {
                        '& > fieldset': {
                          borderColor: 'white',
                        },
                      },
                    }}
                    InputProps={{
                      style: textFieldStyles,
                    }}
                    value={email}
                    onChange={(e) => handleEmailChange(e.target.value)}
                    style={{ marginTop: '.5rem' }}
                    type="email"
                    required
                    variant="outlined"
                    fullWidth
                    error={errorEmail}
                    helperText={errorEmail ? 'Invalid email address' : ''}
                  />
                </Grid>
                <Grid item xs={6}>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <Typography
                      style={{
                        fontSize: '14px',
                        fontWeight: '300',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: 'white',
                      }}
                    >
                      {t('contact_form_phone_number')}
                    </Typography>
                    <Typography
                      style={{
                        textAlign: 'end',
                        fontSize: '14px',
                        fontWeight: '300',
                        fontStyle: 'normal',
                        lineHeight: 'normal',
                        color: 'white',
                      }}
                    >
                      {t('contact_form_optional')}
                    </Typography>
                  </div>

                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <CustomFormControl
                      variant="outlined"
                      style={{
                        marginTop: '.5rem',
                        marginRight: '.5rem',
                        width: '8rem',
                      }}
                    >
                      <Select
                        value={countryCode}
                        onChange={(e) =>
                          handlePhoneCountryChange({ value: e.target.value })
                        }
                      >
                        {CountryCodes.map((country, index) => (
                          <MenuItem key={index} value={country.dial_code}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </CustomFormControl>
                    <TextField
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& > fieldset': { borderColor: 'none' },
                        },
                        '& .MuiOutlinedInput-root.Mui-focused': {
                          '& > fieldset': {
                            borderColor: 'white',
                          },
                        },
                        '& .MuiOutlinedInput-root:hover': {
                          '& > fieldset': {
                            borderColor: 'white',
                          },
                        },
                      }}
                      InputProps={{
                        style: textFieldStyles,
                      }}
                      value={phoneNumber}
                      onChange={(e) => handlePhoneNumberChange(e.target.value)}
                      style={{ marginTop: '.5rem' }}
                      type="text"
                      variant="outlined"
                      fullWidth
                      error={phoneError}
                      helperText={phoneError ? 'Please enter only numbers' : ''}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: 'white',
                    }}
                  >
                    {t('contact_form_service')}
                  </Typography>
                  <CustomFormControl
                    variant="outlined"
                    fullWidth
                    style={{ marginTop: '.5rem' }}
                  >
                    <Select
                      value={singularity_type}
                      defaultValue={'Singularity Startup'}
                      onChange={(e) =>
                        handleSingularityTypeChange(e.target.value)
                      }
                    >
                      <MenuItem value={'Singularity Startup'}>
                        Singularity Startup
                      </MenuItem>
                      <MenuItem value={'Singularity Agency'}>
                        Singularity Agency
                      </MenuItem>
                    </Select>
                  </CustomFormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: 'white',
                    }}
                  >
                    {t('contact_form_text_area')}
                  </Typography>
                  <TextField
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& > fieldset': { borderColor: 'none' },
                      },
                      '& .MuiOutlinedInput-root.Mui-focused': {
                        '& > fieldset': {
                          borderColor: 'white',
                        },
                      },
                      '& .MuiOutlinedInput-root:hover': {
                        '& > fieldset': {
                          borderColor: 'white',
                        },
                      },
                    }}
                    InputProps={{
                      style: textFieldStyles,
                    }}
                    value={message}
                    onChange={(e) => handleMessageChange(e.target.value)}
                    style={{ marginTop: '.5rem' }}
                    type="text"
                    required
                    placeholder={t('contact_form_text_area_placeholder')}
                    fullWidth
                    rows={8}
                    variant="outlined"
                    multiline
                    error={errorMessage}
                    helperText={errorMessage ? 'Minimum is 20 characters' : ''}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <CustomCheckbox
                    color="secondary"
                    checked={checked}
                    onChange={handleCheckBoxChange}
                    style={{ marginRight: '1rem' }}
                  />
                  <Typography
                    style={{
                      marginTop: '.8rem',
                      fontSize: '14px',
                      fontWeight: '300',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      color: 'white',
                    }}
                  >
                    {t('contact_form_privacy_policy')}
                  </Typography>
                  {showError && (
                    <Typography
                      variant="body2"
                      color="error"
                      marginTop=".55rem"
                      marginLeft="auto"
                    >
                      You must agree to the terms and conditions.
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <ReCAPTCHA
                    sitekey="6Lf81cMnAAAAAK4_Dxo-7BOV2La9-BTN7ha-DLVO"
                    onChange={verifyCaptcha}
                  />
                </Grid>
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <Button
                    disabled={!captcha}
                    onClick={handleSubmit}
                    style={{
                      padding: '20px 48px',
                      textDecoration: 'none',
                      backgroundColor: '#0076FF',
                      color: 'white',
                      borderRadius: 0,
                      fontSize: '14px',
                      fontWeight: 300,
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      letterSpacing: '0.56px',
                      textTransform: 'initial',
                    }}
                  >
                    {t('contact_form_send_button')}
                  </Button>
                  {email_response && (
                    <Typography
                      variant="body2"
                      color={email_status === 200 ? 'green' : 'red'}
                      marginTop=".55rem"
                      marginLeft="auto"
                    >
                      {email_response}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Card>
            <div style={{ marginTop: '10rem' }}>
              <h2
                style={{
                  marginTop: '0px',
                  marginBottom: '0px',
                  fontSize: '24px',
                  fontWeight: '300',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  textAlign: 'center',
                  color: '#FFF',
                  whiteSpace: 'pre-line',
                }}
              >
                {t('contact_form_email_us_text')}
              </h2>
              <div
                onClick={handleEmailClick}
                style={{
                  fontSize: '24px',
                  fontWeight: '300',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  textAlign: 'center',
                  color: '#0076FF',
                  display: 'flex',
                  marginTop: '2rem',
                  cursor: 'pointer',
                }}
              >
                <MailOutlineIcon
                  style={{
                    color: '#0076FF',
                    verticalAlign: 'middle',
                    marginTop: '.3rem',
                  }}
                />
                <span style={{ marginLeft: '1rem' }}>
                  sales@globalsingularity.io
                </span>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ContactUs
