import { Button, Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
const button_style = {
  display: 'inline-flex',
  padding: '20px 48px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  borderRadius: '0px',
  backgroundColor: '#0076FF',
  backdropFilter: 'blur(10px)',
  textTransform: 'initial',
}

interface HeaderProps {
  isMobile: boolean
  setOpen: (isOpen: boolean) => void
}

const Header: React.FC<HeaderProps> = ({ isMobile, setOpen }) => {
  const { t } = useTranslation()
  const handleButtonClick = (page: string) => {}
  return (
    <Grid item xs={12} sx={{ textAlign: 'center' }}>
      <h5
        style={{
          fontSize: '1.25rem',
          fontWeight: 400,
          color: '#AAA',
          marginBottom: 0,
        }}
      >
        Speak it. AI will code it.
      </h5>
      <h1
        style={{
          fontSize: '4rem',
          fontWeight: '400',
          margin: isMobile ? '1rem 0' : '26px',
        }}
      >
        Your{' '}
        <span style={{ color: '#0076FF', fontStyle: 'italic' }}>
          word&nbsp;
        </span>{' '}
        is the
        <span style={{ color: '#0076FF', fontStyle: 'italic' }}> command</span>
      </h1>
      <p
        style={{
          margin: 0,
          fontSize: '1.125rem',
          lineHeight: '1.125rem',
          opacity: '50%',
        }}
      >
        Deploy apps rapidly using our text to app solution.
      </p>
      <div
        style={{
          marginTop: '60px',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: isMobile ? '1rem' : '3rem',
        }}
      >
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          sx={button_style}
        >
          {'Try demo'}
        </Button>
        {/* <Button
          onClick={() => handleButtonClick('/services')}
          variant="outlined"
          sx={{
            padding: '20px 24px',
            textAlign: 'center',
            borderRadius: '0px',
            border: '1px solid #FFF',
            color: '#FFF',
            textTransform: 'initial',
          }}
        >
          {'Second button CTA'}
        </Button> */}
      </div>
      <p style={{ marginTop: '28px', color: '#AAA', fontSize: '0.875rem' }}>
        No credit card required
      </p>
    </Grid>
  )
}

export default Header
