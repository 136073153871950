import { Grid } from '@mui/material'
import { t } from 'i18next'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Badge from './Badge'

interface ProjectCardProps {
  handleClick: (key: string) => void
  id: string
  logo: string
  badgeType: string
  isMobile?: boolean
  index?: number
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  handleClick,
  logo,
  id,
  badgeType,
  isMobile,
  index,
}) => {
  return (
    <div
      style={{
        padding: isMobile ? '0 1.5rem' : '0',
        display: 'flex',
        height: '100%',
      }}
    >
      <div
        className={`ourwork_card ${index === 0 ? 'first_element_slider' : ''}`}
      >
        <div
          style={{
            minHeight: '80px',
            alignItems: 'flex-start',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '1rem',
          }}
        >
          {logo && <img src={logo} alt={`${id}-logo`} />}
          {!logo && <div></div>}
          <Badge badgeType={badgeType} />
        </div>
        <h4 className={'ourwork_card_heading'}>{t(`${id}_heading`)}</h4>
        <div className={'ourwork_card_text'}>{t(`${id}_subheading`)}</div>
        <div onClick={() => handleClick(id)} className={'ourwork_card_button'}>
          <ArrowForwardIcon className={'ourwork_card_button_icon'} />
          {t('shared_show_details')}
        </div>
      </div>
    </div>
  )
}

export default ProjectCard
