import { Container, Grid, useMediaQuery, useTheme } from '@mui/material'
import { t } from 'i18next'
import PageHeroBg from '../../../assets/page-hero-bg.webp'
import PageHeader from '../PageHeader'
import NavigationContent from './NavigatedContent'
import HighlightAction from './HighlightAction'
import DiscoverProjects from '../DiscoverProjects'
import ContactUs from '../../Home/ContactUs'

const WeatherForecast = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <>
      <Container>
        <Grid container spacing={12} sx={{ marginBottom: '4rem' }}>
          <PageHeader
            title={'project_header_weather_title'}
            text={'project_header_weather_text'}
            type={'project_header'}
            badge={'research'}
          />
          <NavigationContent />
          <Grid item xs={12}>
            <HighlightAction
              title="highlight_action_weather_title"
              description="highlight_action_weather_description"
              buttonText="highlight_action_weather_button"
            />
          </Grid>
          <Grid item xs={12}>
            <DiscoverProjects currentProject="weather-forecast" />
          </Grid>
          <Grid item xs={12}>
            <ContactUs />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default WeatherForecast
