export const FAQDataHome = [
  {
    title: 'What types of projects does Global Singularity specialize in?',
    text: 'Global Singularity focuses on creating tailored software solutions, including cloud-based applications, micro-service architectures, systems requiring fast access and secured data transfer. We cater to industries such as government, research, healthcare, and technology, ensuring innovative solutions for every project.',
  },
  {
    title: 'How do you ensure scalability and security?',
    text: 'We prioritize scalability and security by designing modular architectures, implementing advanced encryption protocols, and following industry best practices. Our solutions are built to adapt to growing demands while safeguarding data integrity.',
  },
  {
    title: 'Do you offer support services after project delivery?',
    text: 'We provide ongoing support, including performance monitoring, updates, and troubleshooting. Our team is committed to ensuring your software operates smoothly and meets evolving business needs.',
  },
]

export const FAQDataSingularity = [
  {
    title: 'How does the Singularity AI platform work?',
    text: 'The platform simplifies app development by integrating design, backend, architecture, and deployment into a single workflow. With its voice-controlled and drag-and-drop features, developers can automate repetitive tasks and focus on building innovative solutions quickly and efficiently.',
  },
  {
    title: 'What size of companies or projects is Singularity AI suitable for?',
    text: 'Singularity AI is designed primarily for small and medium-sized companies or projects. Its flexible features and pricing model make it ideal for teams looking to streamline their workflows without the need for extensive resources.',
  },
  {
    title: 'What support and resources are available for new users?',
    text: 'We are currently working on extensive onboarding tutorials and comprehensive documentation to ensure new users can quickly and effectively utilize the platform. In the meantime, our technical team is available to assist with any questions or challenges you may have.',
  },
]
