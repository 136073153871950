import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import Slider from 'react-slick'
import { ProjectData } from './ProjectData'
import { useNavigate } from 'react-router-dom'
import ProjectCard from '../common/ProjectCard'

const DiscoverProjects: React.FC<{ currentProject: string }> = ({
  currentProject,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const router = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const handleClick = (routerID: string) => {
    router(`/our-work/${routerID}`)
  }
  const settings = {
    infinite: false,
    arrows: false,
    slidesToShow: isMobile ? 1 : 2,
    speed: 500,
    beforeChange: (current: number, next: number) => {
      setCurrentSlide(next)
    },
  }
  return (
    <>
      <Grid item xs={12} sx={{ marginTop: '4rem' }}>
        <Grid container sx={{ marginBottom: '2.5rem' }}>
          <Grid item xs={12} md={7}>
            <h2 className="section_heading">Discover other projects</h2>
            <div className="section_heading_divider"></div>
          </Grid>
          <Grid item xs={12} md={5}>
            <p className="section_heading_text" style={{ color: '#AAA' }}>
              Explore more software development ventures of Singularity team.
            </p>
          </Grid>
        </Grid>

        <Slider {...settings}>
          {ProjectData.filter(
            (project) => project.routeId !== currentProject,
          ).map((project, index) => {
            return (
              <ProjectCard
                key={project.routeId}
                handleClick={handleClick}
                logo={project.icon}
                id={project.routeId}
                badgeType={project.badge}
                isMobile={isMobile}
                index={index}
              />
            )
          })}
        </Slider>
        <div
          style={{
            height: '2px',
            width: '100%',
            backgroundColor: '#D9D9D9',
            position: 'relative',
            marginTop: '2.5rem',
          }}
        >
          <div
            style={{
              height: '100%',
              width: `${
                ((currentSlide + 1) /
                  (isMobile
                    ? ProjectData.filter(
                        (project) => project.routeId !== currentProject,
                      ).length
                    : ProjectData.filter(
                        (project) => project.routeId !== currentProject,
                      ).length - 1)) *
                100
              }%`,
              backgroundColor: '#0076FF', // You can change the color
              transition: 'width 0.5s ease-in-out',
            }}
          ></div>
        </div>
      </Grid>
    </>
  )
}

export default DiscoverProjects
