import OurWork from '../components/Home/OurWork'
import About from '../components/Home/About'
import Services from '../components/Home/Services'
import SingularityStartup from '../components/Home/SingularityStartup'
import ContactUs from '../components/Home/ContactUs'
import Icons from '../components/Home/Icons'
import React from 'react'
import { Button, Container, useMediaQuery, useTheme } from '@mui/material'
import Grid from '@mui/material/Grid'
import HomePageHeroBg from '../assets/homepage-hero-bg.webp'
import PageHeroBg from '../assets/page-hero-bg.webp'
import '../styles/Home/Home.css'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import References from '../components/Home/References'
import FAQ from '../components/FAQ/FAQ'
import { FAQDataHome } from '../components/FAQ/FAQData'
import Background from '../assets/startup-homepage-hero-bg.webp'

const button_style = {
  display: 'inline-flex',
  padding: '20px 48px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  borderRadius: '0px',
  backgroundColor: '#0076FF',
  backdropFilter: 'blur(10px)',
  textTransform: 'initial',
}

const Home: React.FC = () => {
  const router = useNavigate()
  const { t } = useTranslation()

  const handleButtonClick = (page: string) => {
    router(page)
  }

  return (
    <>
      <Container>
        <Grid container className="home_wrapper" spacing={12}>
          <Grid item lg={12} md={12} xs={12} sm={12}>
            <img src={PageHeroBg} alt="Logo" className={'home_image1'} />
            <img src={HomePageHeroBg} alt="Logo" className={'home_image2'} />
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            sm={12}
            sx={{ textAlign: 'center', paddingTop: { md: '12rem' } }}
          >
            <h2 className={'home_heading'}>{t('home_screen_subheading')}</h2>
            <h1 className={'home_subheading'}>{t('home_screen_heading')}</h1>
            <Button
              onClick={() => handleButtonClick('/contact')}
              variant="contained"
              sx={{ ...button_style, marginX: '1rem', marginBottom: '1rem' }}
            >
              {t('home_screen_button')}
            </Button>
            <Button
              onClick={() => handleButtonClick('/services')}
              variant="outlined"
              sx={{
                padding: '20px 24px',
                marginX: '1rem',
                textAlign: 'center',
                borderRadius: '0px',
                border: '1px solid #FFF',
                color: '#FFF',
                marginBottom: '1rem',
                textTransform: 'initial',
              }}
            >
              {t('home_screen_services_button2')}
              <ArrowForwardIcon sx={{ marginLeft: '1rem' }} />
            </Button>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: '-1rem' }}>
            <OurWork />
          </Grid>
          {/* <Grid item xs={12}>
            <References />
          </Grid> */}
          <Grid item xs={12}>
            <Services />
          </Grid>
          <Grid item xs={12}>
            <About />
          </Grid>
          <Grid item xs={12}>
            <FAQ data={FAQDataHome} />
          </Grid>
          <Grid item xs={12}>
            <SingularityStartup />
          </Grid>
        </Grid>
      </Container>
      <div style={{ position: 'relative' }}>
        {' '}
        <img className="startup_image" src={Background} alt="Background" />
      </div>
      <Container>
        <Grid
          container
          className="home_wrapper"
          sx={{ paddingBottom: '4rem', paddingTop: '4rem' }}
          spacing={12}
        >
          <Grid item xs={12}>
            <ContactUs />
          </Grid>
          <Grid item xs={12}>
            <Icons />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Home
