import React, { useEffect } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
import Home from './pages/Home'
import Navbar from './components/Navbar'
import About from './pages/About'
import Work from './pages/Work'
import SingularityStartupPage from './pages/SingularityStartupPage'
import Services from './pages/Services'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'
import ProjectDetails from './components/Work/ProjectDetails'
import SingularityStartupProcess from './pages/SingularityStartupProcess'
import SingularityStartupPricing from './pages/SingularityStartupPricing'
import Footer from './components/Footer'
import CookieBanner from './components/CookieBanner'
import ContactUsWithCredentials from './components/ContactUsWithCredentials'
import './App.css'
import { createTheme, ThemeProvider } from '@mui/material/styles'

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const theme = createTheme({
  typography: {
    fontFamily: 'Axiforma, Arial, sans-serif',
  },
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Navbar />
        <div style={{ marginTop: 64, overflow: 'hidden' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/our-work" element={<Work />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/singularity-startup"
              element={<SingularityStartupPage />}
            />
            <Route
              path="/singularity-startup/pricing"
              element={<SingularityStartupPricing />}
            />
            <Route
              path="/singularity-startup/process"
              element={<SingularityStartupProcess />}
            />
            <Route path="*" element={<NotFound />} />
            <Route path="/our-work/:routeId" element={<ProjectDetails />} />
            <Route
              path="/contact/:email"
              element={<ContactUsWithCredentials />}
            />
          </Routes>
          <Footer />
          <CookieBanner />
        </div>
      </Router>
    </ThemeProvider>
  )
}

export default App
