import Design from '../../../assets/slide-design.png'
import Backend from '../../../assets/slide-backend.png'
import Architecture from '../../../assets/slide-architecture.png'
import Preview from '../../../assets/slide-preview.png'
import Deploy from '../../../assets/slide-deploy.png'
import Performance from '../../../assets/slide-performance.png'
import Collaborate from '../../../assets/slide-colaborate.png'

export const ProcessData = [
  {
    title: 'Design',
    text: "Singularity wouldn't strive to be the tool it is without a seamless user experience. The design features are well-known, intuitive, and general, so there's no need to waste time relearning what you already know.",
    img: Design,
  },
  {
    title: 'Backend',
    text: 'The back-end provides access to libraries and hundreds of components that can be used to create your app.',
    img: Backend,
  },
  {
    title: 'Architecture',
    text: "You have complete control over your application's cloud architecture and software delivery. You can adjust remote servers and set up restrictions for incoming and outgoing traffic.",
    img: Architecture,
  },
  {
    title: 'Preview',
    text: 'Before deployment, you can review every aspect and component in the Design, Back-end, and Architecture settings, ensuring nothing comes as a surprise.',
    img: Preview,
  },
  {
    title: 'Deploy',
    text: "If everything checks out, you're just one click away from deploying your app and making it ready for market release.",
    img: Deploy,
  },
  {
    title: 'Performance',
    text: 'With Singularity, you can assess cloud performance, response time, and the overall health of your application to identify potential errors.',
    img: Performance,
  },
  {
    title: 'Collaborate',
    text: 'Working on a large project can be overwhelming without an organized workforce. You can invite team members or external contributors to the Singularity project, define their roles, and set access restrictions.',
    img: Collaborate,
  },
]
