import { Grid } from '@mui/material'
import { Link } from 'react-scroll'
import { t } from 'i18next'
import GlacierMelting from './GlacierMelting'
import SunEruptions from './SunEruptions'
import MarsWeather from './MarsWeather'

const sections = ['glacier-melting', 'sun-eruptions', 'mars-weather']

const NavigationContent = () => {
  return (
    <>
      <Grid item xs={12}>
        <div
          style={{
            display: 'flex',
            gap: '1rem',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {sections.map((section) => (
            <Link
              key={section}
              to={section}
              smooth={true}
              duration={500}
              style={{
                padding: '1.25rem 1rem',
                backgroundColor: '#FFFFFF14',
                borderRadius: '100px',
                color: '#AAA',
                fontSize: '0.875rem',
                cursor: 'pointer',
              }}
            >
              {t(section)}
            </Link>
          ))}
        </div>
        <GlacierMelting />
        <SunEruptions />
        <MarsWeather />
      </Grid>
    </>
  )
}

export default NavigationContent
