import React from 'react'
import Grid from '@mui/material/Grid'
import PageHeroBg from '../assets/page-hero-bg.webp'
import ProjectsOverview from '../components/Work/ProjectsOverview'
import ContactUs from '../components/Home/ContactUs'
import Icons from '../components/Home/Icons'
import '../styles/Work/Work.css'
import { Container, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

const Work: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <Container>
        <Grid container sx={{ paddingBottom: '4rem' }} spacing={12}>
          <Grid item xs={12}>
            <img src={PageHeroBg} alt="Logo" className={'work_image'} />
          </Grid>
          <Grid item xs={12}>
            <div className={'work_subheading'}>{t('our_work_subheading')}</div>
            <h1 className={'work_heading'}>{t('our_work_heading')}</h1>
            <div
              className={'work_text'}
              style={{ marginLeft: 'auto', marginRight: 'auto' }}
            >
              {t('our_work_text')}
            </div>
          </Grid>
          <Grid item xs={12}>
            <ProjectsOverview />
          </Grid>
          <Grid item xs={12}>
            <ContactUs />
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default Work
