import { Grid } from '@mui/material'
import Icons from '../../Home/Icons'
import Nvidia from '../../../assets/nvidia.png'
import WebSummit from '../../../assets/websummit.png'
import AWS from '../../../assets/aws.png'
import Hopero from '../../../assets/hopero.png'

const Partners = () => {
  const items = [
    { id: 1, icon: Nvidia, height: 35 },
    { id: 2, icon: WebSummit, height: 40 },
    { id: 3, icon: AWS, height: 40 },
    { id: 4, icon: Hopero, height: 40 },
  ]
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12} md={2}>
          <div
            style={{
              fontSize: '1.5rem',
              lineHeight: '1.5rem',
              whiteSpace: 'nowrap',
            }}
          >
            Start-up <br />
            partners
          </div>
        </Grid>
        <Grid item xs={12} md={10}>
          <Icons customIcons={items} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Partners
