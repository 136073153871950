import { t } from 'i18next'

interface SolutionProps {
  text: string
  solutionList: string[]
}

const Solution: React.FC<SolutionProps> = ({ text, solutionList }) => {
  return (
    <>
      <h3
        style={{
          fontSize: '2rem',
          fontWeight: 400,
          marginTop: 0,
          marginBottom: '1rem',
        }}
      >
        Solution
      </h3>
      <p style={{ color: '#AAA' }}>{t(text)}</p>
      {solutionList.map((solution) => (
        <div key={solution}>
          <h3 style={{ fontSize: '1rem', color: '#AAA' }}>{t(solution)}</h3>
          <p style={{ color: '#AAA' }}>{t(`${solution}_text`)}</p>
        </div>
      ))}
    </>
  )
}

export default Solution
