import { Grid } from '@mui/material'
import { Element } from 'react-scroll'
import Challenges from './Challenges'
import Solution from './Solution'
import Value from './Value'
import Mars from '../../../assets/mars.png'

const ChallengesList = [
  'mars_weather_challenge_list_1',
  'mars_weather_challenge_list_2',
  'mars_weather_challenge_list_3',
]

const SolutionList = [
  'mars_weather_solution_1',
  'mars_weather_solution_2',
  'mars_weather_solution_3',
  'mars_weather_solution_4',
  'mars_weather_solution_5',
]

const ValueList = [
  'mars_weather_value_1',
  'mars_weather_value_2',
  'mars_weather_value_3',
]

const MarsWeather = () => {
  return (
    <>
      <Element name="mars-weather">
        <h2
          className="section_heading"
          style={{ textAlign: 'center', marginTop: '5rem' }}
        >
          Mars Weather Prediction
        </h2>
        <div
          className={'section_heading_divider'}
          style={{
            margin: 'auto',
          }}
        ></div>
        <Grid container sx={{ marginTop: '4rem' }} spacing={4}>
          <Grid item xs={12} lg={6}>
            <img src={Mars} alt={Mars} style={{ maxWidth: '100%' }} />
          </Grid>
          <Grid item xs={12} lg={6}>
            <p style={{ fontSize: '1rem', color: '#AAA', margin: 0 }}>
              Predicting weather on Mars is a crucial step in ensuring the
              success of future exploration missions and the sustainability of
              human presence on the Red Planet. Accurate weather forecasts can
              protect technological assets, guide mission planning, and enhance
              our understanding of Martian atmospheric dynamics.
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Challenges
              listStart="mars_weather_challenge_start"
              text="mars_weather_challenge_text"
              list={ChallengesList}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Solution
              text="mars_weather_solution_text"
              solutionList={SolutionList}
            />
            <Value valueList={ValueList} />
          </Grid>
        </Grid>
      </Element>
    </>
  )
}

export default MarsWeather
