import { Button, Grid } from '@mui/material'

const Schedule: React.FC<{ transparent?: boolean }> = ({ transparent }) => {
  const handleButtonClick = () => {
    const url =
      'https://calendly.com/sales-9gm/consultation-with-singularity-agency/consultation-for-singularity-agency'
    window.open(url, '_blank')
  }
  return (
    <Grid
      item
      xs={12}
      sx={{
        height: '400px',
        backgroundColor: transparent ? 'transparent' : '#0076FF',
        marginTop: '-4rem',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h2 className="section_heading" style={{ textAlign: 'center' }}>
        Schedule a demo
      </h2>
      <div
        className={'section_heading_divider'}
        style={{ backgroundColor: '#FFF', textAlign: 'center', opacity: '50%' }}
      ></div>
      <p
        style={{
          fontWeight: 900,
          color: '#AAA',
          marginTop: '2rem',
          marginBottom: '2rem',
          fontSize: '1rem',
        }}
      >
        Learn more with a real-time presentation.
      </p>
      <Button
        onClick={handleButtonClick}
        variant="contained"
        sx={{
          padding: '20px 24px',
          textAlign: 'center',
          borderRadius: '0px',
          fontWeight: 900,
          fontSize: '0.875rem',
          color: '#FFF',
          textTransform: 'initial',
          backgroundColor: transparent ? '#0076FF' : '#000',
        }}
      >
        Book a call
      </Button>
    </Grid>
  )
}

export default Schedule
