import { Button, Grid } from '@mui/material'

interface SectionTextProps {
  headline: string
  text: string
  buttonText?: string
  handleClick?: () => void
}

const SectionText: React.FC<SectionTextProps> = ({
  headline,
  text,
  buttonText,
  handleClick,
}) => {
  return (
    <>
      <h2 className="section_heading" style={{ marginBottom: '1rem' }}>
        {headline}
      </h2>
      <div className={'section_heading_divider'}></div>
      <p
        style={{
          fontWeight: 900,
          color: '#AAA',
          marginTop: '2rem',
          marginBottom: '2rem',
          fontSize: '1rem',
        }}
      >
        {text}
      </p>
      <Button
        onClick={handleClick}
        variant="contained"
        sx={{
          padding: '12px 24px',
          textAlign: 'center',
          borderRadius: '0px',
          fontWeight: 900,
          fontSize: '0.875rem',
          lineHeight: '1rem',
          color: '#FFF',
          textTransform: 'initial',
          backgroundColor: '#0076FF',
        }}
      >
        {buttonText}
      </Button>
    </>
  )
}

export default SectionText
