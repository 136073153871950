import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface SectionProps {
  headline: string
  name?: string
  text: string
  image?: string
  position?: string
}

const Section: React.FC<SectionProps> = ({
  headline,
  text,
  name,
  image,
  position = 'left',
}) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={4}>
      <Grid
        item
        xs={12}
        lg={7}
        sx={{
          paddingY: '2rem',
          order: position === 'left' ? 1 : 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {name && <div className={'section_heading_top'}>{t(name)}</div>}
        <h2 className={'section_heading'}>{t(headline)}</h2>
        <div className={'section_heading_divider'}></div>
        <div className={'section_text'}>{t(text)}</div>
      </Grid>
      <Grid
        item
        xs={12}
        lg={5}
        sx={{
          order: position === 'left' ? 2 : 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            backgroundColor: '#1C1C1C',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            minHeight: '300px',
            paddingTop: 0,
          }}
        >
          {image?.length && <img src={image} alt="image" />}
        </Box>
      </Grid>
    </Grid>
  )
}

export default Section
