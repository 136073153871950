import { Container, Grid, useMediaQuery, useTheme } from '@mui/material'
import Header from './Header'
import { ReferenceData } from './ReferenceData'
import Reference from '../../Home/Reference'
import ApplicationDescription from './ApplicationDescription'
import Schedule from './Schedule'
import Partners from './Partners'
import SectionText from './SectionText'
import Producitivy from '../../../assets/productivity.png'
import Speed from '../../../assets/speed.png'
import Cost from '../../../assets/cost.png'
import { OneApplication } from './OneApplication'
import Process from './Process'
import ReactPlayer from 'react-player/lazy'
import FAQ from '../../FAQ/FAQ'
import ContactUs from '../../Home/ContactUs'
import DiscoverProjects from '../DiscoverProjects'
import Background from '../../../assets/startup-homepage-hero-bg.webp'
import { FAQDataSingularity } from '../../FAQ/FAQData'
import PageHeroBg from '../../../assets/page-hero-bg.webp'
import CustomDialog from '../../CustomDialog'
import { useState } from 'react'

const SingularityProject = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [open, setOpen] = useState(false)

  return (
    <>
      <Container>
        <Grid container spacing={12} sx={{ marginBottom: '4rem' }}>
          <Grid item xs={12}>
            <img src={PageHeroBg} alt="Logo" className={'work_image'} />
          </Grid>
          <Header isMobile={isMobile} setOpen={setOpen} />
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <ReactPlayer
              url="https://www.youtube.com/watch?v=-Kz-LuaWlQw"
              width={'auto'}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Grid container spacing={2}>
              {ReferenceData.map((reference) => (
                <Grid
                  key={reference.author}
                  item
                  xs={12}
                  sm={12}
                  md={6} // Show 6 items on medium screens
                  lg={4} // Show 4 items on large screens
                >
                  <Reference reference={reference} />
                </Grid>
              ))}
            </Grid>
          </Grid> */}
          <Grid item xs={12}>
            <ApplicationDescription />
          </Grid>
        </Grid>
      </Container>
      <Schedule />
      <Container>
        <Grid
          container
          spacing={6}
          sx={{ marginTop: '3rem', marginBottom: '4rem' }}
        >
          <Grid item xs={12}>
            <Partners />
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} lg={7} sx={{ textAlign: 'center' }}>
                <img src={Speed} alt={Speed} style={{ maxWidth: '100%' }} />
              </Grid>
              <Grid
                item
                xs={12}
                lg={5}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                }}
              >
                <SectionText
                  headline="Deploy apps rapidly"
                  text="Harness the power of Singularity tool for accelerated app deployment, reducing time-to-market significantly."
                  buttonText="Try demo"
                  handleClick={() => setOpen(true)}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12} sx={{ backgroundColor: '#0F0F0F' }}>
        <Grid
          container
          sx={{
            padding: '2rem',
            maxWidth: '1216px',
            margin: 'auto',
            paddingY: '4rem',
          }}
        >
          <Grid
            item
            xs={12}
            lg={5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'baseline',
            }}
          >
            <SectionText
              headline="Save cost deliver faster"
              text="Utilize our AI-powered development with Singularity to efficiently manage resources, curbing expenses, and achieve swifter project delivery."
              buttonText="Try demo"
              handleClick={() => setOpen(true)}
            />
          </Grid>
          <Grid item xs={12} lg={7} sx={{ textAlign: 'center' }}>
            <img src={Cost} alt={Cost} style={{ maxWidth: '100%' }} />
          </Grid>
        </Grid>
      </Grid>
      <Container>
        <Grid container spacing={12} sx={{ marginTop: '-3rem' }}>
          <Grid item xs={12} lg={7} sx={{ textAlign: 'center' }}>
            <img
              src={Producitivy}
              alt={Producitivy}
              style={{ maxWidth: '100%' }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'baseline',
            }}
          >
            <SectionText
              headline="Boost your productivity"
              text="Experience a remarkable increase in your productivity through optimized processes and enhancing features."
              buttonText="Try demo"
              handleClick={() => setOpen(true)}
            />
          </Grid>
          <Grid item xs={12}>
            <h2
              className="section_heading"
              style={{ textAlign: 'center', marginBottom: '1rem' }}
            >
              Everything in one application
            </h2>
            <div
              className="section_heading_divider"
              style={{ margin: 'auto' }}
            ></div>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              sx={{
                marginBottom: '4rem',
              }}
            >
              {OneApplication.map((dataItem) => (
                <Grid key={dataItem.name} item xs={12} md={6} lg={4}>
                  <div
                    style={{
                      backgroundColor: '#171717',
                      minHeight: '370px',
                      padding: '2.5rem',
                      display: 'flex',
                      flexDirection: 'column',
                      boxSizing: 'border-box',
                    }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <img src={dataItem.img} alt={dataItem.img} />
                    </div>

                    <h3
                      className="section_heading"
                      style={{ fontSize: '1.5rem' }}
                    >
                      {dataItem.name}
                    </h3>
                    <div className="section_heading_divider"></div>
                    <div
                      className="section_text"
                      style={{ fontSize: '0.875rem', marginTop: '1rem' }}
                    >
                      {dataItem.text}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <h2
              className="section_heading"
              style={{ textAlign: 'center', marginBottom: '1rem' }}
            >
              The process
            </h2>
            <div
              className="section_heading_divider"
              style={{ margin: 'auto' }}
            ></div>
          </Grid>
          <Grid item xs={12}>
            <Process />
          </Grid>
          <Grid item xs={12}>
            <FAQ data={FAQDataSingularity} />
          </Grid>
          <Grid item xs={12}>
            <Schedule transparent={true} />
          </Grid>
        </Grid>
      </Container>
      <div style={{ position: 'relative' }}>
        {' '}
        <img className="startup_image" src={Background} alt="Background" />
      </div>
      <Container>
        <Grid container spacing={12} sx={{ marginBottom: '4rem' }}>
          <Grid item xs={12}>
            <DiscoverProjects currentProject="singularity-ai" />
          </Grid>
          <Grid item xs={12}>
            <ContactUs />
          </Grid>
        </Grid>
      </Container>
      <CustomDialog open={open} onClose={() => setOpen(false)} />
    </>
  )
}

export default SingularityProject
