import { Grid } from '@mui/material'
import { Element } from 'react-scroll'
import Challenges from './Challenges'
import Solution from './Solution'
import Value from './Value'
import SunEruption from '../../../assets/sun-eruption.jpg'

const ChallengesList = [
  'sun_eruptions_challenge_list_1',
  'sun_eruptions_challenge_list_2',
  'sun_eruptions_challenge_list_3',
]

const SolutionList = [
  'sun_eruptions_solution_1',
  'sun_eruptions_solution_2',
  'sun_eruptions_solution_3',
  'sun_eruptions_solution_4',
  'sun_eruptions_solution_5',
]

const ValueList = [
  'sun_eruptions_value_1',
  'sun_eruptions_value_2',
  'sun_eruptions_value_3',
]

const SunEruptions = () => {
  return (
    <>
      <Element name="sun-eruptions">
        <h2
          className="section_heading"
          style={{ textAlign: 'center', marginTop: '5rem' }}
        >
          Monitoring Sun Eruptions
        </h2>
        <div
          className={'section_heading_divider'}
          style={{
            margin: 'auto',
          }}
        ></div>
        <Grid container sx={{ marginTop: '4rem' }} spacing={4}>
          <Grid item xs={12} lg={6}>
            <img
              src={SunEruption}
              alt={SunEruption}
              style={{ maxWidth: '100%' }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <p style={{ fontSize: '1rem', margin: 0, color: '#AAA' }}>
              Solar eruptions are powerful phenomena that have the potential to
              disrupt technology, communication systems, and power grids on
              Earth. Understanding and predicting these events are critical to
              minimize their impacts and ensuring the safety of modern
              infrastructure.
            </p>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Challenges
              listStart="sun_eruptions_challenge_start"
              text="sun_eruptions_challenge_text"
              list={ChallengesList}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Solution
              text="sun_eruptions_solution_text"
              solutionList={SolutionList}
            />
            <Value valueList={ValueList} />
          </Grid>
        </Grid>
      </Element>
    </>
  )
}

export default SunEruptions
